import React, { Fragment, useState, useEffect } from 'react'
import Footer from 'components/Footer'
import Header from 'components/Header'
import Billing from 'containers/accounts/billing'
import { Flex, Container } from '@chakra-ui/react'

const Page = ({ history }) => {

  return (
    <Fragment>
      <Flex
        justify={'center'}>
        <Container
            maxW={'1440px'}
            mr={{base: '0px', md: '10px'}}
            ml={{base: '0px', md: '60px'}}>
            <div className="content">
                <div id="main">
                    <div autoscroll="">
                        <Billing />
                    </div>
                </div>
            </div>
        </Container>
      </Flex>
    </Fragment>
    )
}

export default Page
import React, { useEffect, useState } from 'react'
import SideBar from 'components/SideBarOld'
import { useSelector, useDispatch, } from 'react-redux'
import loading from '../../assets/images/loading.gif'

import actions from './redux/action'
import { fetchApi } from 'actions/api'
import { message } from 'antd'
import { Stack, Flex, Container, Heading } from '@chakra-ui/react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  Button
} from '@chakra-ui/react'

const { getBilling } = actions

const Page = ({ }) => {
  const dispatch = useDispatch()
  const [fetching, setFetching] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [code, setCode] = useState('')
  const [codeType, setCodeType] = useState(null)

  useEffect(() => {
    dispatch(getBilling())

    return () => { }
  }, [])

  const onSubmit = (event) => {
    event.preventDefault()

    setFetching(true)
    fetchApi('post', 'clapi/premium/exchangePremiumKey/', { premium_code: code })
      .then((data) => {
        console.log({ data })
        if (data && data.error) {
          message.error(data.error)
          setErrorMessage(data.error)
        } else {

        }
        setFetching(false)
      })
      .catch((error) => {
        console.log({ error })
        message.error('Redeem code or code type is not valid')
        setErrorMessage('Redeem code or code type is not valid')
        setFetching(false)
      })
  }

  const types = [{ id: 1, name: "Premium" }]

  return (
    <Flex
      justify={'center'}
    >
      <Container 
        mt={'10px'}
        maxW={'1450px'}
        mr={{base: '0px', md: '10px'}}
        ml={{base: '0px', md: '60px'}}
      >
        <FormControl maxW={'500px'}  >
          <FormLabel>Redeem code</FormLabel>
          <Input type='text' />

          <FormLabel mt={'10px'}>Code type:</FormLabel>
          <Select tabIndex={'3'} type='text' placeholder='- Choose Code Type -' value={codeType} onChange={event => setCodeType(event.target.value)} required>
            {types.map((item) => {
                return (
                    <option value={item.id}>{item.name}</option>
                )
            })}
          </Select>
          <Button my={'20px'} onClick={onSubmit} type="submit" colorScheme='blue' isLoading={fetching} loadingText='Submitting'>Submit report</Button>
        </FormControl>

{/*       
        <div className="padding-top-30 padding-bottom-30">
          <div className="row padding-bottom-100">
              <SideBar />
            <div className="large-10 push-0 columns">
              <div id="redeem">
                <form id="redeem_form" novalidate="novalidate" ng-submit="processForm()">
                  <div className="row">
                    <div className="large-8 columns">
                      <Heading as='h2' size='lg'>
                        Redeem Code
                      </Heading>
                      <p>
                        <label for="redeem_code">Redeem code:</label>
                        <input type="text" name="redeem_code" id="redeem_code" tabIndex="2" placeholder="Redeem Code" value={code} onChange={event => setCode(event.target.value)} required />
                      </p>
                      <p>
                        <label for="code_type">Code type:</label>
                        <select type="text" name="code_type" id="code_type" tabIndex="3" value={codeType} onChange={event => setCodeType(event.target.value)} required>
                            <option value="">- Choose Code Type -</option>
                            {types.map((item) => {
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                      </p>
                      <button onClick={onSubmit} type="submit" className="button expand">Submit</button>
                      {fetching &&
                          <div className="loader show">
                              <img id="loading-image" src={loading} alt="Loading..." />
                          </div>
                      }
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> */}
    </Container>
</Flex>

          
      
  )
}

export default Page
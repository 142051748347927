import React, { Fragment, useState, useEffect } from 'react'
import Report from 'containers/accounts/report'
import { Flex, Container } from '@chakra-ui/react'

const Page = ({ history }) => {
  return (
    <Fragment>
        <Flex
          justify={'center'}>
          <Container
            maxW={'1440px'}
            mr={{base: '0px', md: '10px'}}
            ml={{base: '0px', md: '60px'}}
            >
            <div className="content">
                <div id="main">
                    <div autoscroll="">
                        <Report />
                    </div>
                </div>
            </div>
          </Container>
        </Flex>
    </Fragment>
  )
}

export default Page
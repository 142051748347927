import React, { useEffect } from 'react'
import SideBar from 'components/SideBarOld'
import { useSelector, useDispatch } from 'react-redux'
import {
  HStack, Heading, TableContainer, Table, Thead, Tr, Th,Text, Tbody, Td
} from '@chakra-ui/react'

import actions from './redux/action'

const { getBilling } = actions

const Page = ({ }) => {
  const dispatch = useDispatch()

  useEffect(() => {
      dispatch(getBilling('2022-02-05T12:45:11.775Z', '2022-07-05T12:45:11.775Z'))
      return () => { }
  }, [])

  const userBilling = useSelector((state) => state.account.billing)
  const bills = userBilling ? userBilling : []

  return (
    <div className="padding-top-30 padding-bottom-30">
    <HStack
        justifyContent={'space-between'}
        my={'30px'}
      >
        <Heading as='h5' size='md'>
          Billing Information
        </Heading>
      </HStack>
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th><Text color={'black'} fontWeight='bold' size={'50px'}>ID</Text></Th>
              <Th><Text color={'black'} fontWeight='bold' size={'50px'}>Plan</Text></Th>
              <Th><Text color={'black'} fontWeight='bold' size={'50px'}>Bill Amount</Text></Th>
              <Th><Text color={'black'} fontWeight='bold' size={'50px'}>Date</Text></Th>
              <Th><Text color={'black'} fontWeight='bold' size={'50px'}>Bill ID</Text></Th>
            </Tr>
          </Thead>
          <Tbody>
          {bills.map((bill) => {
            return (
              <Tr key={bill.pk}>
                <Td>{bill.pk}</Td>
                <Td>{bill.fields.plan_id}</Td>
                <Td>{bill.fields.money_charged}</Td>
                <Td >{bill.fields.created_date}</Td>
                <Td>{bill.fields.detail._id}</Td>
              </Tr>
            )
          })}
          </Tbody>
        </Table>
      </TableContainer>
      {/* <div className="row padding-bottom-100">
        <div className="large-10 push-0 columns">
          <h5>Billing Information</h5>
          <table role="grid" className="table fixed_layout">
            <thead>
                <tr>
                  <th>ID</th>
                  <th>Plan</th>
                  <th>Bill Amount</th>
                  <th>Date</th>
                  <th>Bill ID</th>
                </tr>
            </thead>
            <tbody>
              {bills.map(bill => {
                  return (
                    <tr>
                      <td>{bill.pk}</td>
                      <td>{bill.fields.plan_id}</td>
                      <td className="success">{bill.fields.money_charged}</td>
                      <td>{bill.fields.created_date}</td>
                      <td>{bill.fields.detail._id}</td>
                    </tr>
                  )
              })}
            </tbody>
          </table>
        </div>
      </div> */}
    </div>
  )
}

export default Page
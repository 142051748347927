import React, { useEffect, useState } from 'react'
import SideBar from 'components/SideBarOld'
import { useSelector, useDispatch } from 'react-redux'
import loading from '../../assets/images/loading.gif'
import Modal from 'react-modal'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  HStack,
  Heading,
  Text,
  Button
} from '@chakra-ui/react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import { Textarea } from '@chakra-ui/react'
import { Select } from '@chakra-ui/react'
import actions from './redux/action'
import { SessionStatusFilter } from 'actions/constants'
import { fetchApi } from 'actions/api'
import moment from 'moment'

const { getReport } = actions

const Page = ({ }) => {
  const [openModal, setOpenModal] = useState(false)
  const [message, setMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [reportType, setReportType] = useState('BUG')
  const [reportUrl, setReportUrl] = useState('')
  const [fetching, setFetching] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.currentUser)

  const closeModal = () => {
    setOpenModal(false)
    setMessage('')
    setReportUrl('')
    setReportType('BUG')
  }

  const onOpenModal = () => {
    setOpenModal(true)
  }

  const sendReport = (event) => {
    event.preventDefault()

    if (message.length == 0) {
      setErrorMessage('Report message is required')
      return
    }
    if (reportType.length == 0) {
      setErrorMessage('Report type is required')
      return
    }

    let file_id = 0

    if (reportType == 'DCMA') {
      if (reportUrl.length == 0) {
        setErrorMessage('Report url is required')
        return
      }
      let url_path_array = reportUrl.split('/')
      file_id = url_path_array[2]
    }

    setFetching(true)
    setErrorMessage('')
    fetchApi('post', 'clapi/session/createReport/', { sid: user.user_id, text: reportType, detail: message, fid: file_id })
    .then((data) => {
      console.log({ data })
      setFetching(false)

      if (data && data.session_id) {
        closeModal()
        dispatch(getReport('2014-01-01'))
      } else if (data && data.error) {
        setErrorMessage(data.error)
      } else {
        setErrorMessage('Failed to send report. Please try again')
      }
    })
    .catch((error) => {
      console.log({ error })
      setFetching(false)
      setErrorMessage('Failed to send report. Please try again')
    })
  }

  useEffect(() => {
    dispatch(getReport('2014-01-01'))

    return () => { }
  }, [])

  const userReport = useSelector(state => state.account.reports)
  const reports = userReport && userReport.results ? userReport.results : []

  return (
    <div className="padding-top-30 padding-bottom-30">
      <HStack
        justifyContent={'space-between'}
        my={'30px'}
      >
        <Heading as='h5' size='md'>
          Report
        </Heading>
        <Button colorScheme='blue' onClick={onOpenModal}>Create report</Button>
      </HStack>
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th><Text color={'black'} fontWeight='bold' size={'50px'}>Report</Text></Th>
              <Th><Text color={'black'} fontWeight='bold' size={'50px'}>Status</Text></Th>
              <Th><Text color={'black'} fontWeight='bold' size={'50px'}>Details</Text></Th>
              <Th><Text color={'black'} fontWeight='bold' size={'50px'}>Date</Text></Th>
            </Tr>
          </Thead>
          <Tbody>
          {reports.map((report) => {
            return (
              <Tr key={report.id.toString()}>
                <Td>{report.text}</Td>
                <Td>{SessionStatusFilter[report.status]}</Td>
                {/* <td className="{{(report.status| enumSessionStatus: report.status === 'completed') ? 'success' : 'danger'}}">{SessionStatusFilter[report.status]}</td> */}
                <Td >{(report.data.detail === "") ? report.data.file_link : report.data.detail}</Td>
                <Td>{moment(report.created).format('hh:mm YYYY-MM-DD')}</Td>
              </Tr>
            )
          })}
          </Tbody>
        </Table>
      </TableContainer>
        <Modal
          isOpen={openModal}
          onRequestClose={closeModal}
          style={customStyles}
          appElement={document.getElementById('root')}
        >
        <FormControl>
          <FormLabel>Report type*:</FormLabel>
          <Select placeholder='Select report' onChange={event => setReportType(event.target.value)} required="required">
            <option value='BUG' selected={reportType === 'BUG'}>BUG</option>
            <option value='HELP' selected={reportType === 'HELP'}>HELP</option>
            <option value='DMCA' selected={reportType === 'DMCA'}>DMCA</option>
          </Select>
          <FormLabel for="detail">Message:*</FormLabel>
          <Textarea placeholder='Please provide more details' size='sm' rows={'7'} onChange={event => setMessage(event.target.value)}/>
          {/* <textarea name="detail" id="detail" cols="12" rows="6" tabIndex="3" ng-model="$parent.detail" value={message} onChange={event => setMessage(event.target.value)}></textarea> */}
          {reportType === 'DMCA' &&
              <label ng-show="text=='DMCA'" for="file_url">URL:*</label>
            }
          {reportType === 'DMCA' &&
            <input id="file_url" ng-model="$parent.file_url" ng-show="text=='DMCA'" type="text" value={reportUrl} onChange={event => setReportUrl(event.target.value)} />
          }
          {errorMessage && errorMessage.length > 0 &&
            <Alert status='error'
              my={'10px'}
            >
              <AlertIcon />
              {errorMessage}
            </Alert>
          }
          <Alert status='warning' my={'10px'}>
            <AlertIcon />
            This report is a serious legal document - please be careful to be accurate in your report. You may wish to first notice that Storagon DO NOT take any responbility for your actions.
          </Alert>
          <HStack justifyContent={'space-between'}>
           <Button onClick={sendReport} type="submit" colorScheme='blue' isLoading={fetching} loadingText='Submitting'>Submit report</Button>
           <Button onClick={closeModal} type="submit" colorScheme='red'>Cancel</Button>
          </HStack>
        </FormControl>
          {/* <form name="report-form" id="report-form">
            <label for="text">Report type*:</label>
            <select id="text" ng-model="$parent.text" required="required" onChange={event => setReportType(event.target.value)}>
              <option value="BUG" selected={reportType === 'BUG'}>BUG</option>
              <option value="HELP" selected={reportType === 'HELP'}>HELP</option>
              <option value="DMCA" selected={reportType === 'DMCA'}>DMCA</option>
            </select>
          </form> */}
         
          {/* {fetching &&
            <div className="loader" style={{ display: 'block' }}>
              <img id="loading-image" src={loading} alt="Loading..." />
            </div>
          } */}
        </Modal>
    </div>
  )
}

export default Page

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '560px',
    width: '90%',
    backgroundColor: 'white'
  },
  overlay: {
    backgroundColor: '#000000cc'
  }
}